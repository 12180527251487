<template>
    <v-container fluid class="ma-0 pa-0">
    <v-layout row wrap class="clear-background">
      <v-flex sm12 xs12 >
        <v-layout row wrap class="pa-4 profile-background" align-center fill-height >
            <v-flex offset-sm7 sm4 d-flex>
              <v-layout  column fill-height>
               <p class="font-16">Developer</p>
              <h3 class="banner-title uppercase">Arturo González</h3>
              </v-layout>
            </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs12 pa-4 class="white-background">
        <p class="nunito-black accent--text text-center font-18 py-8"> Frase </p>
      </v-flex>

      <v-flex xs12 class="white-background" pb-12>
        <v-flex sm10 offset-sm2 pb-12>
          <v-layout row wrap>
              <v-flex sm6 pa-6>
                <p class="nunito-black accent--text font-20"> {{ $ml.get('profile_title_1') }} </p>
                <p class="font-16" style="max-width: 75%">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              </v-flex>
              <v-flex sm6 pa-6>
                <p class="nunito-black accent--text font-20"> {{ $ml.get('profile_title_2') }} </p>
                <ul>
                    <li>Lorem ipsum dolor sit amet</li>
                    <li>Lorem ipsum dolor sit amet</li>
                    <li>Lorem ipsum dolor sit amet</li>
                </ul>  
              </v-flex>
              <v-flex sm6 pa-6>
                <p class="nunito-black accent--text font-20"> {{ $ml.get('profile_title_3') }} </p>
                <p class="font-16" style="max-width: 75%">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              </v-flex>
              <v-flex sm6 pa-6>
                <p class="nunito-black accent--text font-20"> {{ $ml.get('profile_title_4') }} </p>
                <v-layout style="display: flex; justify-content: space-between; max-width: 70%">
                    <i class="material-icons">music_note</i>
                    <i class="material-icons">directions_run</i>
                    <i class="material-icons">library_books</i>
                    <i class="material-icons">directions_boat</i>
                </v-layout>
              </v-flex>
          </v-layout>
        </v-flex>
      </v-flex>

    </v-layout>
    </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>
